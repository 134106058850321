'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import NextError from 'next/error';

type ErrorProps = {
    error: Error & { digest?: string };
    reset: () => void;
};

/**
 * エラーが発生した際のグローバルエラーコンポーネント
 *
 * @see https://nextjs.org/docs/app/building-your-application/routing/error-handling
 */
export default function GlobalError({ error }: ErrorProps) {
    const statusCode = error.digest ? 500 : 404;

    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        <>
            <NextError
                statusCode={statusCode}
                title="エラーが発生しました"
                reset={() => {
                    window.location.reload();
                }}
            />
        </>
    );
}
